import classes from './NounDivider.module.css';
const NounDividerEmpty = () => {
    return (
        <div className={classes.wrapper}>
            <p></p>
        </div>
    );
};

export default NounDividerEmpty;
